import { html, css, svg, LitElement } from 'lit'

export class DRTHead extends LitElement {
  static styles = css`
    svg {
      overflow: visible;
    }
  `

  static properties = {
    _deg: { type: Number },
    _headerHeight: { type: Number },
    _headerWidth: { type: Number },
    _titles: { type: Array }
  }

  constructor () {
    super()
    const headers = Array.from(this.querySelectorAll('div'))
    this._headerWidth = headers[0].offsetWidth
    this._titles = headers.map((header) => header.innerText)
    this._handleResize()
  }

  connectedCallback () {
    super.connectedCallback()
    window.addEventListener('resize', this._handleResize)
  }

  disconnectedCallback () {
    window.removeEventListener('resize', this._handleResize)
    super.disconnectedCallback()
  }

  // Using arrow function so `this` refers to component, not window.
  _handleResize = () => {
    const smallScreen = window.innerWidth < 1024
    this._headerHeight = smallScreen ? 400 : 320
    this._deg = smallScreen ? -90 : -45
  }

  renderSVG (title) {
    const colMid = this._headerWidth / 2

    return html`<svg
      xmlns="http://www.w3.org/2000/svg"
      height="${this._headerHeight}"
      width="${this._headerWidth}"
      viewBox="0 0 ${this._headerWidth} ${this._headerHeight}"
      alt="${title}"
    >
      ${svg`<text x="${colMid}" y="${
        this._headerHeight + 2
      }" transform="rotate(${this._deg} ${colMid},${this._headerHeight})">
              ${title}
            </text>`}
    </svg>`
  }

  render () {
    return html`${this._titles.map(
      (title) => html`<div>${this.renderSVG(title)}</div>`
    )}`
  }
}
window.customElements.define('dr-thead', DRTHead)
