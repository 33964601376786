import { select } from "d3";

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="spiderweb"
export default class extends Controller {
  static targets = ["chart", "labels", "polygons"];
  static values = { sectors: Array };

  connect() {
    const height = viewPortY(this.chartTarget);
    this.size = viewPortX(this.chartTarget);

    if (height != this.size) {
      throw "chart not quadratic";
    }

    const radius = this.size / 2;
    const radiansPerSector = (2 / this.sectorsValue.length) * Math.PI;
    const origin: [number, number] = [radius, radius];

    const drawSector = ({ name, levels_total, level_reached }, sector) => {
      const sectorRads = radiansPerSector * sector;
      const sectorMiddleRads = radiansPerSector * (sector + 0.5);
      const nextSectorRads = radiansPerSector * (sector + 1);
      const levelLength = radius / levels_total;

      select(this.labelsTarget)
        .append("text")
        .attr("x", origin[0] + 0.5 * radius * Math.cos(sectorMiddleRads))
        .attr("y", origin[1] - 0.5 * radius * Math.sin(sectorMiddleRads))
        .attr("text-anchor", "middle")
        .text(name);

      for (var level = 0; level < levels_total; level++) {
        const p1: [number, number] = [
          origin[0] + level * (levelLength * Math.cos(sectorRads)),
          origin[1] - level * (levelLength * Math.sin(sectorRads)),
        ];
        const p2: [number, number] = [
          origin[0] + (level + 1) * (levelLength * Math.cos(sectorRads)),
          origin[1] - (level + 1) * (levelLength * Math.sin(sectorRads)),
        ];
        const p3: [number, number] = [
          origin[0] + (level + 1) * (levelLength * Math.cos(nextSectorRads)),
          origin[1] - (level + 1) * (levelLength * Math.sin(nextSectorRads)),
        ];
        const p4: [number, number] = [
          origin[0] + level * (levelLength * Math.cos(nextSectorRads)),
          origin[1] - level * (levelLength * Math.sin(nextSectorRads)),
        ];
        const polygon = [p1, p2, p3, p4];

        const fill = level_reached > level ? "auto" : "#fff";

        select(this.polygonsTarget)
          .append("polygon")
          .style("fill", fill)
          .attr("points", polygon);
      }
    };

    this.sectorsValue.map(drawSector);
  }
}

const viewPortY = (svg: SVGAElement): number => {
  return Number.parseInt(svg.attributes["viewBox"].nodeValue.split(",")[3]);
};

const viewPortX = (svg: SVGAElement): number => {
  return Number.parseInt(svg.attributes["viewBox"].nodeValue.split(",")[2]);
};
